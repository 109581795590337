import React, { useState, useEffect } from "react";
import userCircle from "../../../src/assets/images/User_Circle_1_.png";
import { Button, Navbar, NavDropdown } from "react-bootstrap";
import "./Header.css";
import { logout } from "../../Reducers/authSlice";
import { Link, useNavigate } from "react-router-dom";
import glabollogo from "../../assets/images/glabol-logo.png";
import { ToastContainer, toast } from "react-toastify";
import { useAppDispatch } from "../../hook";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import login_icon from "../../assets/images/login_icon.png";
import logout_icon from "../../assets/images/logout_icon.png";
import { useAppSelector } from "../../hook";
function Header() {
  const [hamStyle, sethamStyle] = useState<React.CSSProperties>({
    display: "none",
  });

  const showLoader = (text: string) => {
    Swal.fire({
      text: text,
      allowOutsideClick: false,
      showConfirmButton: false,
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const isAuthenticated = useAppSelector((state: any) => {
    return state.auth.isAuthenticated;
  });

  const opensidebar = () => {
    sethamStyle({
      ...hamStyle,
      display: hamStyle.display === "none" ? "block" : "none",
    });
  };

  const navigate = useNavigate();
  const navigatemenu = useNavigate();

  const navbarStyle: React.CSSProperties = {
    background: "#DC1B5E",
    top: 0,
    position: "sticky",
    zIndex: 800,
  };
  const dropdownStyle: React.CSSProperties = {
    color: "#FFFFFF",

    fontSize: "25px",
    textDecoration: "none",
  };

  const handleLoginClick = () => {
    navigate("/login");
  };
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useAppDispatch();
  const [menuData, setMenuData] = useState<any>([]);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    destination: "",
    numberOfTraveller: "",
    mobile: "",
    email: "",
    traveler_month: "",
  });

  const handleChange = (e: any) => {
    let { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    const mobilePattern = /^[0-9]{10}$/;
    if (name === "mobile") {
      if (!mobilePattern.test(value)) {
        setMobileError("Please enter a valid 10-digit mobile number");
      } else {
        setMobileError("");
      }
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (name === "email") {
      if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
      }
    }
  };

  const isFormValid = () => {
    return Object.values(formData).every((value) => value !== "");
  };

  const linkStyle: React.CSSProperties = {
    color: "#DC1B5E",
  };

  const enquiryBtnStyle: React.CSSProperties = {};
  const handleSubmit = async () => {
    showLoader("Sending enquiry  Please Wait");

    try {
      const url = `${process.env.REACT_APP_API_URL}/masterEquiry/createEnquiry`;
      const response = await axios.post(url, formData);
      if (response?.data?.success === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response?.data?.message,
          confirmButtonText: "OK",
        }).then(async (result: any) => {
          if (result) {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response?.data?.message,
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleLogoutClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("userData");
        dispatch(logout());
        navigate("/login");
      }
    });
  };

  useEffect(() => {
    menData();
  }, []);

  const menData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getAddedmenu`
      );
      setMenuData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ top: "50%", transform: "translateY(-50%)" }}
      />
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex={1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Enquiry Form
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div>
              <div className="row d-flex px-3 mt-3">
                <div className="col-lg-6 ">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      name="name"
                      placeholder="Enter Your Name"
                      style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Destination<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text "
                      className="form-control"
                      id="exampleFormControlInput1"
                      name="destination"
                      placeholder="Enter Your Destination"
                      style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                      value={formData.destination}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-flex px-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Number of Travellers
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="exampleFormControlInput1"
                      name="numberOfTraveller"
                      placeholder="Enter Number Of Travellers"
                      style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                      value={formData.numberOfTraveller}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Mobile Number<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      className={`form-control ${
                        mobileError ? "is-invalid" : ""
                      }`}
                      id="exampleFormControlInput1"
                      name="mobile"
                      placeholder="Enter Your Mobile Number"
                      style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                      value={formData.mobile}
                      onChange={handleChange}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                      }}
                      maxLength={10}
                    />
                    {mobileError && (
                      <div className="invalid-feedback">{mobileError}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row d-flex px-3">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Email ID<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        emailError ? "is-invalid" : ""
                      }`}
                      id="exampleFormControlInput1"
                      name="email"
                      placeholder="Enter Your Email ID"
                      style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {emailError && (
                      <div className="invalid-feedback">{emailError}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Month
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlInput1"
                      name="traveler_month"
                      style={{ border: "0.76px solid rgba(255, 169, 35, 1)" }}
                      value={formData.traveler_month}
                      onChange={handleChange}
                    >
                      <option value="">Select Month</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="modal-footer border-0">
                <button
                  className="btn btn-color m-auto mb-2 "
                  style={{
                    padding: "7px 37px",
                    fontSize: "20px",
                    borderRadius: "10px",
                    background: "#ffA923",
                    margin: "4px 2px",
                    color: "#FFF",
                  }}
                  onClick={handleSubmit}
                  disabled={!isFormValid()}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header_bx   ">
        <div className="upper_head container d-flex justify-content-between align-items-center px-3">
          <div className="hamburger-menu ">
            <i
              className="fa-solid fa-xl fa-bars"
              style={{ color: "#dc1b5e" }}
              onClick={() => {
                opensidebar();
              }}
            ></i>
          </div>

          <Link className="logo w-25" to="/">
            <img src={glabollogo} alt="GlobalLogo" className="global-logo" />
          </Link>

          <div className=" desktop_menu  d-flex justify-content-between align-items-center">
            <Link className="txt-link " to="/gallery">
              GALLERY
            </Link>

            <Link className=" txt-link" to="/aboutus">
              ABOUT US
            </Link>

            <div className=" mx-3">
              {isAuthenticated ? (
                <Button
                  className="logout-btn mx-4 position-relative"
                  onClick={handleLogoutClick}
                >
                  LOG OUT{" "}
                  <img
                    src={logout_icon}
                    className="position-absolute logout_inner_icon"
                    alt=""
                  />
                </Button>
              ) : (
                <Button
                  className="login-btn  mx-4 position-relative"
                  onClick={handleLoginClick}
                >
                  LOG IN{" "}
                  <img
                    className="position-absolute logout_inner_icon"
                    src={login_icon}
                    alt=""
                  />
                </Button>
              )}
              <Button
                className=" enquiry-btn"
                style={enquiryBtnStyle}
                data-bs-toggle="modal"
                href="#exampleModalToggle"
              >
                ENQUIRY
              </Button>
              {isAuthenticated ? (
                <Link to="/maincustomer">
                  <img
                    src={userCircle}
                    alt="User"
                    className="img-fluid profile-img"
                  />
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="lower_head">
        <Navbar
          style={navbarStyle}
          expand="lg"
          aria-controls="navbarNav"
          className="container d-flex flex-wrap justify-content-start align-items-center"
        >
          {menuData.map((card: any, index: number) => (
            <div
              key={index}
              className="d-flex justify-content-start mx-3 menu-submenu-container"
              style={{ padding: "8px" }}
              onMouseEnter={() => setShowSubmenu(true)}
              onMouseLeave={() => setShowSubmenu(false)}
            >
              <NavDropdown
                className="d-flex menu justify-content-start align-items-center text-uppercase"
                title={card.category}
                id="basic-nav-dropdown"
                style={dropdownStyle}
                show={showSubmenu}
              >
                {card?.submneuArr.map((item: any, index: number) => (
                  <NavDropdown.Item className="menu-submenu" key={index}>
                    <div
                      onClick={() => {
                        const slugUrl = item?.menu
                          ?.toLowerCase() // Convert to lowercase
                          .replace(/\s+/g, "-");
                        navigate(`/featured/${slugUrl}`, {
                          state: {
                            itemid: item._id,
                            srun: true,
                            // source: "menu",
                          },
                        });
                      }}
                    >
                      {item.menu}
                    </div>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </div>
          ))}
        </Navbar>
      </div>

      <div className="lineX "></div>
      <div className="ham_page " style={hamStyle}>
        <div className="ham_data">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {isAuthenticated ? (
              <div className="item_2 d-flex justify-content-center align-items-center ">
                <button
                  className="accordion-button collapsed accr_item2 "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target=""
                  aria-expanded="false"
                  aria-controls=""
                  onClick={() => {
                    navigatemenu("/maincustomer");
                    sethamStyle({
                      ...hamStyle,
                      display: hamStyle.display === "none" ? "block" : "none",
                    });
                  }}
                >
                  <i
                    className="border  rounded fa-solid fa-lg fa-user mar2"
                    style={{ color: "#000000" }}
                  ></i>{" "}
                  PROFILE
                </button>
              </div>
            ) : null}

            <hr className="m-0" />

            {menuData.map((card: any, index: number) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`flush-heading${index}`}>
                  <button
                    className="accordion-button collapsed accr_item text-uppercase accrr_btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}`}
                  >
                    {card.category}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    {card?.submneuArr.map((item: any, subIndex: number) => (
                      <div
                        className="mx-3 for-mobileview-header-submenu"
                        key={`${index}-${subIndex}`}
                        onClick={() => {

                          const slugUrl = item.menu
                            ?.toLowerCase()
                            .replace(/\s+/g, "-");

                          navigate(`/featured/${slugUrl}`, {
                            state: {
                              itemid: item._id,
                              srun: true,
                              // source: "menu",
                            },
                          });
                          sethamStyle({
                            ...hamStyle,
                            display:
                              hamStyle.display === "none" ? "block" : "none",
                          });
                        }}
                      >
                        {item.menu}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}

            <div className="item_2">
              <button
                className="accordion-button collapsed accr_item2"
                onClick={() => {
                  navigatemenu("/gallery");
                  sethamStyle({
                    ...hamStyle,
                    display: hamStyle.display === "none" ? "block" : "none",
                  });
                }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
              >
                GALLERY
              </button>
            </div>
            <hr className="m-0" />

            <div className="item_2">
              <button
                className="accordion-button collapsed accr_item2"
                onClick={() => {
                  navigatemenu("/aboutus");
                  sethamStyle({
                    ...hamStyle,
                    display: hamStyle.display === "none" ? "block" : "none",
                  });
                }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target=""
                aria-expanded="false"
                aria-controls=""
              >
                ABOUT US
              </button>
            </div>
            <hr className="m-0" />

            <div className="d-flex justify-content-center align-items-center m-4 mob_ham">
              {isAuthenticated ? (
                <Button
                  className="logout-btn position-relative mx-2"
                  onClick={() => {
                    handleLogoutClick();

                    sethamStyle({
                      ...hamStyle,
                      display: hamStyle.display === "none" ? "block" : "none",
                    });
                  }}
                >
                  LOG OUT{" "}
                  <img
                    src={logout_icon}
                    className="position-absolute logout_inner_icon"
                    alt=""
                  />
                </Button>
              ) : (
                <Button
                  className="login-btn position-relative mx-2"
                  onClick={() => {
                    navigate("/login");
                    sethamStyle({
                      ...hamStyle,
                      display: hamStyle.display === "none" ? "block" : "none",
                    });
                  }}
                >
                  LOG IN{" "}
                  <img
                    src={login_icon}
                    className="position-absolute logout_inner_icon"
                    alt=""
                  />
                </Button>
              )}
              <Button
                className=" enquiry-btn2  "
                style={enquiryBtnStyle}
                data-bs-toggle="modal"
                href="#exampleModalToggle"
                onClick={() => {
                  sethamStyle({
                    ...hamStyle,
                    display: hamStyle.display === "none" ? "block" : "none",
                  });
                }}
              >
                ENQUIRY
              </Button>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
}
export default Header;
