import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import map from "../../../assets/images/map.png";
import address from "../../../assets/images/address.png";
import home from "../../../assets/images/home.png";
import contact from "../../../assets/images/contact.png";
import mail from "../../../assets/images/mail.png";
import site from "../../../assets/images/site.png";
import insta from "../../../assets/images/insta.png";
import fb from "../../../assets/images/fb.png";
import twitter from "../../../assets/images/twitter.png";
import link from "../../../assets/images/link.png";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { RedirectUrlAfterLogin } from "../../repeatComponent/RedirectUrlAfterLogin";
import Swal from "sweetalert2";

function ContactUs() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    message: "",
  });

  const [response, setResponse] = useState(null);
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_API_URL}/contact-us`;
      const response = await axios.post(url, formData);

      if (response?.data?.success === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Thank You For Contact",
        });

        // Clear the form data
        setFormData({
          first_name: "",
          last_name: "",
          mobile: "",
          email: "",
          message: "",
        });

        // Clear the email validation
        setIsValidEmail(true);
      } else {
        setError("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <RedirectUrlAfterLogin />
      <div className="contactBox ">
        <div className="bx container flex-column d-flex justify-content-center align-items-center">
          <div className="heading-txt ">
            <h2 className="">Get in Touch</h2>
            <img className="iconsprop " src={map} alt="logo" />
          </div>
          <div className="centerbx ">
            <div className="left">
              <div className="inform ">
                <h4 className="mt-3 m-2 " id="sndmsg">
                  Send Message
                </h4>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        className="idesign form-control m-1 mt-2"
                        placeholder="First Name"
                        aria-label="First name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="idesign form-control m-1 mt-2"
                        placeholder="Last Name"
                        aria-label="Last name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        className={`idesign form-control m-1 mt-2 ${
                          !isValidEmail ? "is-invalid" : ""
                        }`}
                        placeholder="Email Address"
                        aria-label="First name"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const inputValue = e.target.value;
                          setEmail(inputValue);

                          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          const isValid = emailPattern.test(inputValue);

                          setIsValidEmail(isValid);
                        }}
                      />
                      {!isValidEmail && (
                        <div className="invalid-feedback">
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="idesign form-control m-1 mt-2"
                        placeholder="Mobile Number"
                        aria-label="Mobile Number"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        onInput={(e:any) => {
                          const input = e.target as HTMLInputElement;
                          input.value = input.value.replace(/[^0-9]/g, "");
                        }}
                        maxLength={10}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        className="idesign idesign2 form-control m-1 mt-2"
                        id="formGroupExampleInput2"
                        placeholder="Write Your Message Here"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button type="submit" className="btn sendbtn m-2 w-25">
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="right d-flex justify-content-center align-items-center">
              <div className="contactinfo ">
                <h5 className="addr mx-3 font-weight-bold"> Contact info</h5>
                <div className="info-icons d-flex justify-content-start mx-2 align-items-center">
                  <img className="mx-2" src={address} alt="Co.Name" />
                  <div className="iconss"> Glabol India</div>
                </div>
                <div className="info-icons d-flex justify-content-start mx-2 align-items-center">
                  <img className="mx-2" src={home} alt="Address" />
                  <div className="iconss">
                    Address - 1881D, Above UCO Bank, Near Lucky Bakery, Sudama
                    Nagar.
                  </div>
                </div>
                <div className="info-icons d-flex justify-content-start mx-2 align-items-center">
                  <img className="mx-2" src={contact} alt="Contact" />
                  <div className="iconss"> +91 89622 45021</div>
                </div>
                <div className="info-icons d-flex justify-content-start mx-2 align-items-center">
                  <img className="mx-2" src={mail} alt="Email" />
                  <div className="iconss"> info@glabol.com</div>
                </div>
                <div className="info-icons d-flex justify-content-start mx-2 align-items-center">
                  <img className="mx-2" src={site} alt="Website" />
                  <div className="iconss"> https://www.glabol.com</div>
                </div>
                <div className="info-icons d-flex justify-content-start mx-2 align-items-center">
                  <div className="info-icons">
                    <img className="mx-2" src={insta} alt="Instagram" />
                    <img className="mx-2" src={fb} alt="facebook" />
                    <img className="mx-2" src={twitter} alt="twitter" />
                    <img className="mx-2" src={link} alt="Linkedin" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
