import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "./FetchCliecnt";
import type { RootState } from "../store";

const initialState={
     status : "",
     error: "",
     searchTourData:[],
     searchTourData2:[]
}

export const searchTour = createAsyncThunk(
  "tour/searchTour",
  async (body: any, thunkAPI) => {
    
    const {period,category,adventure} = body;
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/search-tour`,{
            menumaster_id:category,
            adventure_type:adventure ,
            period:period
          } 
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

const searchTourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
     
  },

  extraReducers: (builder: any) => {
    builder
      .addCase(searchTour.pending, (state: any) => {
        state.status = false;
      })
      .addCase(searchTour.fulfilled, (state: any, action: any) => {
        state.error = "";
        state.searchTourData= action.payload.getData;
        state.searchTourData2 = action.payload.menumasterData;
      })
      .addCase(searchTour.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export const userList = (state: RootState) => state.auth;
export default searchTourSlice.reducer;
