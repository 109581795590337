import React from 'react'
import { useState } from 'react'
import { Loader } from '../loader/Loader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paymentfaileds from '../../assets/images/paymentfailed.png';

function Paymentfailed() {

    const [showLoader, setShowLoader] = useState<boolean>(true);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string>('');
  const[objId, setObjId] = useState<string>('');

    
    useEffect(() => {
      const userData = localStorage.getItem('userData');
      const checkoutResponse = localStorage.getItem('checkoutResponse');
      if (userData) {
        const { firstname } = JSON.parse(userData);
        setFirstName(firstname); 
      }
      if(checkoutResponse){
        const{objId} = JSON.parse(checkoutResponse);  
        setObjId(objId);
      }
        const timer = setTimeout(() => {
          navigate(`/checkout`,{
          state: objId,
        });
        }, 5000); 
    
        const loaderTimer = setTimeout(() => {
            setShowLoader(false);
          }, 5000); 
      
          return () => {
            clearTimeout(timer); 
            clearTimeout(loaderTimer);
          };
        }, [navigate, objId]);
  return (
    <>
     {showLoader && <Loader/>}

      <div className="container">
        <div className="row text-center">
          <div className="col-sm-6 col-lg-12 col-sm-offset-3">
            <br /><br />
             <img src={Paymentfaileds}/>
            <h2 style={{ color: 'red' }} className='mt-3'>! Failed</h2>
            <h3>Dear, {firstName}</h3> 
            <p style={{ fontSize: '20px', color: '#5C5C5C' }}>Your Payment failed"</p>
            <br /><br />
          </div>
        </div>
      </div>
    </>
  )
}

export default Paymentfailed