import React from "react";
import "../disclaimer/Disclaimer.css";
import banneri from '../../assets/images/twoman.jpg'
function Disclaimer() {
  return (
    <div>
      <div style={{ position: "relative", maxWidth: "100%", height: "auto" }}>
         <div className="image-container">
      <img src={banneri} alt="About Us Page" className="bannerig" />
      <div className="gradient-overlay"></div>
    </div>
        <div
          className="psitr"
        >
          <h1 style={{ fontSize: "4vw" }}>Disclaimer</h1>
          <p>Glabol India</p>
        </div>
      </div>
      <div className="container">
        <h2 className="headingg"> Disclaimer</h2>

        <p className="para_dis">
          Glabol is not responsible for, and expressly disclaims all liability
          for, damages of any kind arising out of use, reference to, or reliance
          on any information contained within the site. While the information
          contained within the site is periodically updated, no guarantee is
          given that the information provided in this Website is correct,
          complete, and up-to-date.
        </p>
        <p className="para_dis">
          Although the Glabol Website may include links providing direct access
          to other Internet resources, including Web sites, Glabol is not
          responsible for the accuracy or content of information contained in
          these sites.
        </p>
        <p className="para_dis">
          Links from wanderon.in to third-party sites do not constitute an
          endorsement by Glabol of the parties or their products and services.
          The appearance on the Web site of advertisements and product or
          service information does not constitute an endorsement by Glabol.
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
