import React, { useState, useEffect } from "react";
import "./wishlist.css";
import { Button } from "react-bootstrap";
import { MdOutlineWatchLater } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Loader } from "../loader/Loader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const WishList = () => {
  const [wishlistData, setWishlistData] = useState<any[]>([]);
  const [load, setLoad] = useState<boolean>(true);

  useEffect(() => {
    getWishlist();
  }, []);

  const navigate = useNavigate();

  function formatTotalAmount(amount: any) {
    amount = parseFloat(amount);

    if (isNaN(amount)) {
      return "0";
    }

    return amount.toLocaleString("en-IN");
  }

  const getWishlist = async () => {
    setLoad(true);
    try {
      const data: any = localStorage.getItem("userData");
      const parsedData = JSON.parse(data);
      const requestData = {
        id: parsedData?._id,
      };
      const url = `${process.env.REACT_APP_API_URL}/get-all-wishlist-byuserid`;
      const response = await axios.post(url, requestData);
      if (response.data.success) {
        setWishlistData(response.data.data);
      } else {
        setWishlistData([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoad(false);
  };

  const MySwal = withReactContent(Swal);

  const removeFromWishlist = async (_id: string) => {
    try {
      const confirmation = await MySwal.fire({
        title: "Are you sure?",
        text: "You want to remove this item from the wishlist",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove it!",
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        const url = `${process.env.REACT_APP_API_URL}/post-wishlist`;
        const data: any = localStorage.getItem("userData");
        const parsedData = JSON.parse(data);
        const requestData = {
          user_id: parsedData?._id,
          tour_id: _id,
        };
        const response: any = await axios.put(url, requestData);
        if (response.data.success) {
          setWishlistData((prevState) =>
            prevState.filter((item) => item._id !== _id)
          );
          Swal.fire({
            icon: "success",
            title: "Item removed from wishlist",
          });
          getWishlist();
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to remove item from wishlist",
          });
        }
      } else if (confirmation.dismiss === Swal.DismissReason.cancel) {
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to remove item from wishlist",
      });
    }
  };

  return (
    <>
      {load ? <Loader /> : ""}
      <div className="container">
        <div className="row">
          <div>
            <h1 className="WishList-h1 customer-border">Wishlist</h1>
          </div>
        </div>
        {wishlistData?.length > 0 ? (
          <div className="row">
            {wishlistData.map((item, index) => (
              <div key={index} className="col-md-6 col-12 mt-2">
                <div className="card m-2 WishList-card WishList-content">
                  <div className="row d-flex justify-content-between align-items-center g-0">
                    <div className="col-md-4 col-5">
                      {/* <Link to={`/tripdetails/${item.tour_id._id}`}> */}
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${item.tour_id.tour_image[0]?.src}`}
                        className="img-fluid card-img-wish"
                        alt="tour image"
                        onClick={() => {
                          const id = item.tour_id._id;
                          const slugUrl = item.tour_id?.title

                            ?.toLowerCase() // Convert to lowercase
                            .replace(/\s+/g, "-");
                          navigate(`/tripdetails/${slugUrl}`, {
                            state: id,
                          });
                        }}
                      />
                      {/* </Link> */}
                    </div>

                    <div className="col-md-8 col-7">
                      <div className="card-body">
                        <h5
                          className="WishList-h5"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "200px",
                          }}
                        >
                          {item.tour_id.title}
                        </h5>
                        <p className="card-text WishList-h5 mb-2">
                          <span>
                            <MdOutlineWatchLater className="watch-Icon" />
                          </span>{" "}
                          {item.tour_id.nightorday}N/
                          {item.tour_id.nightorday + 1}D
                        </p>
                        <p className="WishList-h5 mb-2">
                          ₹{formatTotalAmount(item.tour_id.packageType_price)}
                        </p>
                        <div className="WishList-h5">
                          <Button
                            className="wishlist-btn-btn Removebutton"
                            onClick={() => removeFromWishlist(item.tour_id._id)}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No wishlist items available</p>
        )}
      </div>
    </>
  );
};

export default WishList;
