import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Reducers/authSlice';
import searchTourSlice from './Reducers/searchTourSlice';
// Import other reducers as needed



export const store = configureStore({
  reducer: {
    auth: authSlice,
    tour: searchTourSlice
    
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
