import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "./FetchCliecnt";
import type { RootState } from "../store";

interface AuthState {
  isAuthenticated: boolean;
}
interface TravellerPayload {
  value:{};
}

const initialState: AuthState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
};

export const userLogin = createAsyncThunk(
  "userLogin/sendOtp",
  async (body: any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/user`,
        body
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const userLogin2 =  createAsyncThunk(
  "userLogin2/verifyOtp",
  async (body: any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/checkOtp`,
        body
      );

      if (response.data.success) {
       
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);






// ------- Signup -----
export const userSignup = createAsyncThunk(
  "userSignup/verifyOtp",
  async (body: any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/user-website-send-otp`,
        body
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);
export const userSignup2 = createAsyncThunk(
  "userSignup2/submit",
  async (body: any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/user-website`,
        body
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state: any) => {
      state.isAuthenticated = true;
      localStorage.setItem("isAuthenticated", "true"); 
     
    },
    logout: (state: any) => {
      state.isAuthenticated = false;
      localStorage.setItem("isAuthenticated", "false"); 
    },
    clear: (state: any) => {
      state.response = "";
      state.error = "";
    },
    getTraveller: (state:any, action: PayloadAction<TravellerPayload>) => {
      state.travellerDetails = action.payload;
    },
  },

  extraReducers: (builder: any) => {
    builder
      .addCase(userLogin.pending, (state: any) => {
        state.status = false;
      })
      .addCase(userLogin.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.response = action.meta.requestStatus;
        state.otp = action.payload;
      })
      .addCase(userLogin.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
    builder
      .addCase(userLogin2.pending, (state: any) => {
        state.status = false;
      })
      .addCase(userLogin2.fulfilled, (state: any, action: any) => {
        state.status = true;
      })
      .addCase(userLogin2.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
    builder
      .addCase(userSignup.pending, (state: any) => {
        state.status = false;
      })
      .addCase(userSignup.fulfilled, (state: any, action: any) => {
        state.status = true;
      })
      .addCase(userSignup.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
    builder
      .addCase(userSignup2.pending, (state: any) => {
        state.status = false;
      })
      .addCase(userSignup2.fulfilled, (state: any, action: any) => {
        state.status = true;
      })
      .addCase(userSignup2.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export const userList = (state: RootState) => state.auth;
export const { login, logout, clear,getTraveller} = authSlice.actions;
export default authSlice.reducer;
