import React, { useState, useEffect } from "react";
import { Loader } from "../loader/Loader";
import { useNavigate } from "react-router-dom";
import PaymentImg from "../../assets/images/Paymentanurag.png";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../hook";

interface Traveller {
  Document_Front: { src: string; fileName: string; orgname: string }[];
  name: string;
  mobile: string;
  email: string;
  age: string;
  DOB: { year: number; month: number; day: number };
  country: string;
  gender: string;
  address: string;
  city: string;
  state: string;
  Idproof: string;
  IdNumber: number;
  status: string;
  boarding_point: string;
  roomSharing: string;
  packageType: string;
  pincode: string;
}

function Paymentsuccess() {
  const dispatch = useAppDispatch();
  const tourDetails = useAppSelector((state: any) => state?.auth?.travellerDetails?.value);
  const travellerCountString = localStorage.getItem("TravellerCounts");
  const travellerCount = travellerCountString ? parseInt(travellerCountString) : 0;

  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [travelers, setTravelers] = useState<Traveller[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const responseSuccess = () => {
    const userData = localStorage.getItem("userData");

    try {
      if (userData) {
        const { firstname, lastname } = JSON.parse(userData);
        setFirstName(firstname);
        setLastName(lastname);
      }
    } catch (error) {
      console.error("Error parsing userData:", error);
    }

    const loaderTimer = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    const navigateTimer = setTimeout(() => {
      navigate("/travller"); // Assuming this is the correct navigation path
    }, 1000);

    return () => {
      clearTimeout(loaderTimer);
      clearTimeout(navigateTimer);
    };
  };

  const addTravellersToArray = (count: number) => {
    const obj: Traveller = {
      Document_Front: [{ src: "", fileName: "", orgname: "" }],
      name: "",
      mobile: "",
      email: "",
      age: "",
      DOB: { year: 0, month: 0, day: 0 },
      country: "",
      gender: "",
      address: "",
      city: "",
      state: "",
      Idproof: "",
      IdNumber: 0,
      status: "",
      boarding_point: "",
      roomSharing: "",
      packageType: "",
      pincode: "",
    };

    const newArray: Traveller[] = Array.from({ length: count }, () => ({ ...obj }));
    setTravelers(newArray);
  };

  useEffect(() => {
    addTravellersToArray(travellerCount);
  }, [travellerCount]);

  useEffect(() => {
    if (!apiCalled && travelers.length > 0) {
      const fetchData = async () => {
        const checkoutResponse = localStorage.getItem("checkoutResponse");
        let objId = "";
        
        if (checkoutResponse) {
          const parsedCheckoutResponse = JSON.parse(checkoutResponse);
          objId = parsedCheckoutResponse.objId;
        }

        try {
          setIsLoading(true);
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/setUserTourDetails?applyTour_id=${objId}`,
            { travelers }
          );

          if (response.data.success) {
            responseSuccess();
            localStorage.removeItem("appliedCoupons");
          } else {
            console.error("Failed to fetch data:", response.data.error);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
          setApiCalled(true);
        }
      };

      fetchData();
    }
  }, [travelers, apiCalled]);

  return (
    <>
      {showLoader && <Loader />}

      <div className="container">
        <div className="row text-center">
          <div className="col-sm-6 col-lg-12 col-sm-offset-3">
            <br />
            <br />
            <img src={PaymentImg} alt="payment" />
            <h2 style={{ color: "#0fad00" }}>Success</h2>
            <h3>
              Dear, {firstName} {lastName}
            </h3>
            <p style={{ fontSize: "20px", color: "#5C5C5C" }}>
              "Thank you for booking! Your payment was successful. We appreciate
              your business and look forward to serving you again. Please feel
              free to book more services with us in the future."
            </p>

            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default Paymentsuccess;
