import "./MainCustomer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import mainCustomer from "../../assets/images/Mainimg.png";
import "../customer profile/Voucher.css";
import { Outlet, Link } from "react-router-dom";
import React, {
  useState,
  useEffect,
  ChangeEvent,
  ChangeEventHandler,
} from "react";
import { BsGridFill } from "react-icons/bs";
import { BiHeartCircle } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";
import { FaSwatchbook } from "react-icons/fa6";
import Booking from "../customer profile/Booking";
import Voucher from "./Voucher";
import WishList from "./Wishlist";
import CustomerProfile from "./CustomerProfile";
import axios from "axios";
import { Loader } from "../loader/Loader";
import { string } from "yup";
import userprofile from "../../assets/userprofile.png";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";
interface customerData {
  firstname: string;
  email: string;
  profile_pic: { src: string; orgName: string }[];
}

const MainCustomer = () => {
  const [activeTab, setActiveTab] = useState("v-pills-home");
  const [load, setLoad] = useState<boolean>(true);
  const [useData, setUseData] = useState<customerData[]>([]);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [id, setId] = useState<string | undefined>();
  const [customerData, setCustomerData] = useState(null);

  useEffect(() => {
    const mainId = localStorage.getItem("userData");
   
    if (mainId) {
      const customerId = JSON.parse(mainId);
      setUseData(customerId);
    }
  }, []);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };
  const updateCustomerData = (data: any) => {
    setCustomerData(data);
  };

  const change =(dataa:customerData[])=>{
   setUseData(dataa)
  }
  const renderContent = () => {
    switch (activeTab) {
      case "v-pills-home":
        return <CustomerProfile change={change}  updateCustomerData={updateCustomerData} />;

      case "v-pills-profile":
        return <WishList />;

      case "v-pills-settings":
        return <Voucher />;

      case "v-pills-messages":
        return <Booking />;

      default:
        return null;
    }
  };

  const renderUserDetails = () => {
    return (
      <>
      <RedirectUrlAfterLogin />
        <div className="">
          <div className="card card-section" id="bg-grediant">
            <div className="container">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-md-5 d-flex ">
                    <div className="mb-md-3 profile-icon-container">
                      <label htmlFor="image-upload" className="profile-icon">
                        <img className="imagePic" src={userprofile} />
                      </label>
                    </div>
                    <div className="col-md-8 ">
                      <h3 className="card-title float-top mt-5 user-Name-text">
                        {`${
                          (useData as unknown as { firstname: string })
                            .firstname
                        } ${
                          (useData as unknown as { lastname: string }).lastname
                        }`}
                      </h3>

                      <p className="user-mail-text gmail">
                        {" "}
                        {(useData as unknown as { email: string }).email}
                      </p>
                    </div>
                    <div className="vertical-line1 nonel"></div>
                  </div>

                  <div className="col-md-5 col-12 user-div-2 kd">
                    <h4 className="hello-user_name-h4">
                      Hello{" "}
                      {`${
                          (useData as unknown as { firstname: string })
                            .firstname
                        } ${
                          (useData as unknown as { lastname: string }).lastname
                        }`}!
                    </h4>
                    <h4 className="hello-user_name-p">
                      Welcome Back and Explore the World
                    </h4>
                    <h4 className="hello-user_name-p2">
                      From your account dashboard you can view your recent
                      orders, manage your billing address and edit your password
                      and account details
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderUserDetails()}

      <div className="container forupeer">
        <div className="row ">
          <div className=" col-lg-3  ">
            <p className="smallcard">
              <div
                className="nav flex-column nav-pills pt-4 for_smallnavpill "
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className={`  nav-link dash   ${
                    activeTab === "v-pills-home" ? "active" : ""
                  }`}
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected={activeTab === "v-pills-home"}
                  onClick={() => handleTabClick("v-pills-home")}
                >
                  <span className="col-md-6">
                    <BsGridFill /> &nbsp;
                  </span>
                  <span className="col-md-6 ">Dashboard</span>
                </button>
                <button
                  className={`nav-link dash pe-4 ${
                    activeTab === "v-pills-profile" ? "active" : ""
                  }`}
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected={activeTab === "v-pills-profile"}
                  onClick={() => handleTabClick("v-pills-profile")}
                >
                  <span>
                    <BiHeartCircle className="listicon col-md-6 " />
                  </span>
                  <span className="col-md-6">&nbsp; WishList</span>
                </button>
                <button
                  className={`nav-link dash ${
                    activeTab === "v-pills-messages" ? "active" : ""
                  }`}
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected={activeTab === "v-pills-messages"}
                  onClick={() => handleTabClick("v-pills-messages")}
                >
                  <FaCalendarAlt /> &nbsp; Bookings
                </button>
                <button
                  className={`nav-link dash ${
                    activeTab === "v-pills-settings" ? "active" : ""
                  }`}
                  id="v-pills-settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected={activeTab === "v-pills-settings"}
                  onClick={() => handleTabClick("v-pills-settings")}
                >
                  <FaSwatchbook /> &nbsp; Vouchers
                </button>
              </div>
            </p>
          </div>

          <div className=" col-lg-9  ">
            <p className="bigcard">
              <div id="v-pills-tabContent">
                <div className="card-body ">{renderContent()}</div>
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainCustomer;
