import React from 'react';
import "../career/career.css"


function Career() {
    return (
        <div className="career-container">
            <h1 className="career-title">Traveler Career</h1>
            <p className="career-description">
                Welcome to the Traveler Career page! Explore the journey of an avid traveler, their experiences, and the places they've been. Discover tips, stories, and insights from around the world.
            </p>
            <div className="career-content">
                <div className="career-section">
                    <h2 className="section-title">About Me</h2>
                    <p className="section-content">
                        Hi, I'm Alex, a passionate traveler who has visited over 50 countries. I love sharing my travel experiences, tips, and guides to help fellow travelers explore the world.
                    </p>
                </div>
                <div className="career-section">
                    <h2 className="section-title">Travel Experiences</h2>
                    <ul className="experience-list">
                        <li className="experience-item">Backpacked through Southeast Asia</li>
                        <li className="experience-item">Road tripped across the United States</li>
                        <li className="experience-item">Explored ancient ruins in Peru</li>
                        <li className="experience-item">Dived in the Great Barrier Reef</li>
                    </ul>
                </div>
                <div className="career-section">
                    <h2 className="section-title">Travel Tips</h2>
                    <p className="section-content">
                        Check out my top travel tips to make your journeys smoother and more enjoyable. From packing hacks to finding the best local spots, I've got you covered!
                    </p>
                </div>
            </div>
        </div>
    );
}


export default Career;
