import React from "react";
import "../Terms Conditions/TermsConditions.css";

function TermsConditions() {
  return (
    <div className="terms-container">
      <h1>Terms & Conditions</h1>
      <p>Glabol India</p>
      <div className="container mt-5 ">
        <h2 >Terms & Conditions</h2>
        <ul>
          <li>All our bookings are on the basis of First come First policy.</li>
          <li>
            Bookings are non-transferable, non-adjustable or cannot be
            rescheduled.
          </li>
          <li>
            Trips can be rescheduled if informed 45 days prior the trip (Subject
            to availability of preferred dates).
          </li>
          <li>
            In case of cancellation of bookings from the consumer's end, no
            booking amount shall be refunded or adjusted in any case.
          </li>
          <li>
            The photos/videos content created on Glabol's trip (by Glabol's
            content creators or clients) is the property of Glabol & can also be
            used by Glabol for advertising across media platforms.
          </li>
          <li>
            The IDs shall all be verified before boarding. No boarding shall be
            entertained without a valid Govt. ID.
          </li>
          <li>
            The Transfer of the bookings is not permitted. Only the names
            mentioned at the time of confirmation shall be allowed to travel.
          </li>
          <li>
            Glabol reserves the rights to rearrange the
            itinerary/transportations due to any kind of unavoidable events (i.e
            weather condition, political condition, local union fights or any
            others).
          </li>
          <li>
            An original individual identification proof (Aadhar card, Driving
            License, Voter ID, Passport) must be carried by the traveler during
            the journey.
          </li>
          <li>
            Glabol will not be responsible for any man-made or natural calamity.
            All the sports are safe and approved by the state ministry,
            activities performed will be under the guidance of highly trained
            professionals. However, in case of any injury or accident during the
            adventure sport and travel, Glabol will not be liable.
          </li>
          <li>
            In case of any updates in the booking, the consumer has to give
            prior notice to Glabol.
          </li>
          <li>
            In case of inappropriate weather or any change in local government
            norms, activities mentioned above can be scheduled or canceled
            without any prior notice.
          </li>
          <li>
            Any mischief or inappropriate attitude will not be tolerated and
            Glabol reserves all rights to cancel such bookings without any
            refund at any time during the trip.
          </li>
          <li>
            Glabol can cancel the trip or individual booking without any prior
            notice.
          </li>
        </ul>
        <p>All Rights Reserved by Glabol India.</p>
      </div>{" "}
    </div>
  );
}

export default TermsConditions;
