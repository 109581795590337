import React, { useState, ChangeEvent } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import glabol from "../../assets/images/glabol-logo.png";
import "./Signup.css";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowDown } from "react-icons/io";
import Swal from "sweetalert2";
import { userSignup, userSignup2 } from "../../Reducers/authSlice";
import { useAppSelector } from "../../hook";
import { login } from "../../Reducers/authSlice";

interface StateMappings {
  [abbreviation: string]: string;
}

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const auth = useAppSelector((state: any) => state.auth);
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [state, setState] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [otp, setOtp] = useState<boolean>(false);
  const [mobileError, setMobileError] = useState<string>("");
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [otpReceived, setOtpReceived] = useState(false);
  const [otpValue, setOtpValue] = useState<string>("");
  const [otpFieldDisabled, setOtpFieldDisabled] = useState<boolean>(true);
  const [otpSentTime, setOtpSentTime] = useState<number | null>(null);

  const stateMappings: StateMappings = {
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CG: "Chhattisgarh",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JK: "Jammu and Kashmir",
    JH: "Jharkhand",
    KA: "Karnataka",
    KL: "Kerala",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OD: "Odisha",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TS: "Telangana",
    TR: "Tripura",
    UK: "Uttarakhand",
    UP: "Uttar Pradesh",
    WB: "West Bengal",
    AN: "Andaman and Nicobar Islands",
    CH: "Chandigarh",
    DN: "Dadra and Nagar Haveli",
    DD: "Daman and Diu",
    DL: "Delhi",
    LD: "Lakshadweep",
    PY: "Puducherry",
  };

  // Sample states array
  const states = [
    "AP",
    "AR",
    "AS",
    "BR",
    "CG",
    "GA",
    "GJ",
    "HR",
    "HP",
    "JK",
    "JH",
    "KA",
    "KL",
    "MP",
    "MH",
    "MN",
    "ML",
    "MZ",
    "NL",
    "OD",
    "PB",
    "RJ",
    "SK",
    "TN",
    "TS",
    "TR",
    "UK",
    "UP",
    "WB",
    "AN",
    "CH",
    "DN",
    "DD",
    "DL",
    "LD",
    "PY",
  ];

  // Function to convert state abbreviations to full names
  function getStateFullName(abbreviation: string): string {
    return stateMappings[abbreviation] || "Unknown";
  }
  // Example usage
  states.forEach((state) => {
    const fullName = getStateFullName(state);
  });

  const handleFirstname = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstname(e.target.value);
  };
  const handleLastname = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(e.target.value);
  };

  const SignupSchema = yup.object().shape({
    firstname: yup.string().required("Full Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmailError(!emailPattern.test(value));
  };

  const showLoader = (text: string) => {
    Swal.fire({
      text: text,
      allowOutsideClick: false,
      showConfirmButton: false,
      heightAuto: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isTimerActive) {
      interval = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) {
            setIsTimerActive(false);
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      setRemainingTime(60);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);
  const dismissLoader = () => {
    Swal.close();
    setTimeout(() => Swal.close(), 1000);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  const errorToast = (text: any) => {
    Toast.fire({
      icon: "error",
      iconColor: "white",
      title: text,
    });
  };

  const successToast = (text: any) => {
    Toast.fire({
      icon: "success",
      title: text,
    });
  };

  const handleState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    
    setState(event.target.value);
  };

  const handleMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    const numericValue = input.replace(/\D/g, "");
    if (numericValue.length <= 10) {
      setMobile(numericValue);
    }
  };
  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpValue(e.target.value);
  };

  const handleApi = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (mobile.length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Please enter a valid 10-digit mobile number",
      });
      return;
    }
    if (!email.trim()) {
      errorToast("Please enter your email.");
      return;
    }
    setMobileError("");

    const body: any = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      state: state,
      mobile: mobile,
    };

    dispatch(userSignup(body))
      .unwrap()
      .then((res: any) => {
        if (res.success) {
          if (res.isAlreadyRegistered) {
            Swal.fire({
              icon: "error",
              title: "Mobile number already registerd",
            });
          } else {
            setOtpReceived(true);
            Swal.fire({
              icon: "success",
              title: "OTP sent successfully",
            });
            setIsTimerActive(true);
            setOtpSentTime(Date.now());
          }
        }
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Mobile number already registerd",
        });
      });
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (otpSentTime && Date.now() - otpSentTime > 60000) {
      Swal.fire({
        icon: "error",
        title: "Invalid OTP",
      });

      return;
    }
    const body: any = {
      mobile: mobile,
      otp: otpValue,
    };
    dispatch(userSignup2(body))
      .unwrap()
      .then((res: any) => {
        if (res?.success && res.userDetails?._id) {
          localStorage.setItem("userData", JSON.stringify(res.userDetails));
          dispatch(login());
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            navigate("/");
          }
          Swal.fire({
            icon: "success",
            title: "Registration successful!",
          });
        }
      })
      .catch((error: any) => {
        if (error.message === "Invalid OTP") {
        } else {
          Swal.fire({
            icon: "error",
            title: "Invalid OTP  Please try again",
          });
        }
      });
  };

  return (
    <>
      <div className="background2">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{ top: "50%", transform: "translateY(-50%)" }}
        />

        <div className="mainform">
          <div className="bigdivforms">
            <h6 className="wel2">
              WELCOME TO
              <h1 style={{ textAlign: "center" }}>
                <img
                  src={glabol}
                  className="img-fluid  glabol1"
                  alt="Responsive Image"
                />
              </h1>
            </h6>

            <h6 className="logincontent">
              Sign up to our travel portal and unlock a <br />
              world of possibilities
            </h6>

            <Form>
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={firstname}
                  autoComplete="off"
                  onChange={handleFirstname}
                  className="your-input-class"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupLast">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={lastname}
                  autoComplete="off"
                  onChange={handleLastname}
                  className="your-input-class"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Form.Control
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={handleEmail}
                  isInvalid={emailError}
                  autoComplete="off"
                  className="your-input-class"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupPassword">
                <div className="resend">
                  <Form.Label className="sendlable2">
                    <div className="signarrow">
                      <IoIosArrowDown />
                    </div>
                  </Form.Label>
                </div>
                <Form.Select
                  defaultValue=" "
                  className="your-input-class"
                  value={state}
                  onChange={handleState}
                >
                  <option defaultChecked value={"Select State"}>
                    Select State
                  </option>
                  {states.map((abbreviation, index) => {
                    const fullName = getStateFullName(abbreviation);
                    return (
                      <option
                        key={index}
                        style={{ color: "black" }}
                        value={abbreviation}
                      >
                        {fullName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupPassword">
                <div className="resend">
                  <Form.Label className="sendlable2">
                    {otpReceived && !isTimerActive ? (
                      <Button
                        variant="link"
                        className="sendotp "
                        onClick={handleApi}
                        disabled={!mobile}
                      >
                        Resend OTP
                      </Button>
                    ) : (
                      <Button
                        variant="link"
                        className="sendotp "
                        onClick={handleApi}
                        disabled={!mobile || isTimerActive}
                      >
                        {isTimerActive
                          ? `Resend OTP (${remainingTime}s)`
                          : "Send OTP"}
                      </Button>
                    )}
                  </Form.Label>
                </div>

                <Form.Control
                  type="text"
                  value={mobile}
                  placeholder="Mobile Number"
                  onChange={handleMobile}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                  }}
                  autoComplete="off"
                  className="your-input-class "
                />
                {mobileError && (
                  <p className="text-danger mt-2">{mobileError}</p>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Control
                  type="text"
                  maxLength={4}
                  placeholder="Enter OTP"
                  value={otpValue}
                  onChange={handleOtpChange}
                  className="your-input-class"
                  autoComplete="off"
                />
              </Form.Group>

              <div style={{ textAlign: "center" }}>
                {" "}
                <Button
                  variant="link"
                  className="verifybtn"
                  onClick={handleSubmit}
                  disabled={mobile.length !== 10 || !otpValue}
                >
                  Register
                </Button>
              </div>

              <h2 className="lastpara">
                Existing Account ?
                <Link to={"/login"} className="lastlink">
                  {" "}
                  Sign In
                </Link>
                {error && <p className="text-danger mt-2">{error}</p>}
              </h2>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
