import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BlogDetails.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../loader/Loader";
import { RedirectUrlAfterLogin } from "../../repeatComponent/RedirectUrlAfterLogin";

interface MyDataItemIF {
  _id: string;
  title: string;
  blog_image: { src: string }[];
}

const BlogDetails: React.FC = () => {
  const [getiData, setGetiData] = useState<any>({});
  const [load, setLoad] = useState<boolean>(true);
  const [myData, setMyData] = useState<MyDataItemIF[]>([]);
  const [isError, setIsError] = useState<string | null>(null);
   const location = useLocation();
   const id = location?.state;
  // const { id } = useParams();

  // Reference to scroll to
  const topRef = useRef<HTMLDivElement | null>(null);

  const blogDetApi = async () => {
    setLoad(true);
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/get-blogById`,
      {
        id: id,
      }
    );

    setGetiData(res.data.getData);
    setLoad(false);
  };

  const blogpage = async () => {
    try {
      setLoad(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-all-blog`
      );
      setMyData(res.data.data);
      setLoad(false);
    } catch (error) {
      setIsError("Error fetching data. Please try again later.");
      setLoad(false);
    }
  };

  useEffect(() => {
    blogDetApi();
    blogpage();
  }, []);

  const handleImageClick = (item: MyDataItemIF) => {
    setGetiData(item);

    // Scroll to the top of the main content section when a card is clicked
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (load) {
    return <Loader />;
  }

  if (isError) {
    return <div>{isError}</div>;
  }
  return (
    <>
      <RedirectUrlAfterLogin />
      {load ? <Loader /> : ""}
      <Container className="mt-4 small-view">
        <Row>
          <Col
            className="mt-4 col-md-8 col-lg-9 col-12"
            style={{ position: "relative" }}
          >
            <div ref={topRef}></div> {/* Reference to scroll to */}
            <Card className="border-0">
              <Card.Img
                src={`${process.env.REACT_APP_API_URL}/${getiData?.blog_image?.[0]?.src}`}
                alt="Card image 1"
                className="img-fluid main_img"
                style={{ width: "100%", objectFit: "cover" }}
              />
              <div className="">
                <h5 className="card-title custom-large-text">
                  {getiData?.title}
                </h5>
                <ul className="order-list-text1 text1small">
                  <li
                    className="card-text custom-small-text textsmall1"
                    dangerouslySetInnerHTML={{ __html: getiData?.description }}
                  />
                </ul>
              </div>
            </Card>
            <div className="Blog-hr-line-var"></div>
          </Col>

          <Col md={3} className="mt-4 mb-4 col-md-4 col-lg-3">
            <div className="row row-cols-2 row-cols-md-1 g-4">
              {myData.map((item, index) => (
                <div
                  className="col"
                  key={index}
                  onClick={() => handleImageClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <Card className="forcard-blog-detail">
                    <Card.Img
                      src={`${process.env.REACT_APP_API_URL}/${item.blog_image[0]?.src}`}
                      alt={`Card image ${index + 2}`}
                      className="img-fluid cardss"
                    />
                    <div className="position-absolute overlay-text position-relative">
                      <p className="text-start font-weight-bold blog-details-img-text smhd">
                        {item.title}
                      </p>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogDetails;
