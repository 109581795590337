import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Gallery.css";
import Masonry from "react-responsive-masonry";
import axios from "axios";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { RedirectUrlAfterLogin } from "../repeatComponent/RedirectUrlAfterLogin";

interface ImageData {
  gallery_image: { src: string }[];
}

const Gallery: React.FC = () => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [load, setLoad] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [slides, setSlides] = useState<{ src: string }[]>([]);
  const [videoSlides, setVideoSlides] = useState<{ src: string }[]>([]);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        setLoad(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/get-all-gallery`
        );
        setImages(response.data.data);

        const imageArr = response.data.data.map((image: ImageData) => ({
          src: `${process.env.REACT_APP_API_URL}/${image.gallery_image[0].src}`,
        }));
        setSlides(imageArr);
      } catch (error) {
        console.error("Error fetching gallery images:", error);
      } finally {
        setLoad(false);
      }
    };

    fetchGalleryImages();
  }, []);

  useEffect(() => {
    const fetchVideoSlides = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/get-all-video`
        );
        const videoArr = response.data.data.map(
          (video: { video_url: string }) => ({
            src: video.video_url,
          })
        );
        setVideoSlides(videoArr);
      } catch (error) {
        console.error("Error fetching video slides:", error);
      }
    };

    fetchVideoSlides();
  }, []);

  const handleImageClick = (index: number) => {
    setActiveIndex(index); // Set the clicked image index
    setOpen(true); // Open the lightbox
  };

  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 1,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };

  return (
    <>
      <RedirectUrlAfterLogin />
      <div className="container mt-3">
        <div className="container">
          <h1 className="text-center">
            <span className="our">Our</span>{" "}
            <span className="gallery">Gallery</span>
          </h1>
        </div>

        {Array.isArray(slides) && slides.length > 0 && (
          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={slides}
            index={activeIndex} // Set initial slide with `index`
          />
        )}

        <Masonry columnsCount={3} gutter="10px" className="mb-3">
          {Array.isArray(images) &&
            images.length > 0 &&
            images.map((image, i) => (
              <img
                key={i}
                src={`${process.env.REACT_APP_API_URL}/${image?.gallery_image[0]?.src}`}
                style={{ width: "100%", display: "block", cursor: "pointer" }}
                alt={`Image ${i}`}
                onClick={() => handleImageClick(i)} // Pass index to handleImageClick
              />
            ))}
        </Masonry>

        <div className="mb-5 video-bg">
          <div className="container">
            <div className="mb-5">
              <Swiper
                {...swiperParams}
                navigation
                modules={[Navigation]}
                className="mySwiper"
              >
                {videoSlides.map((video, i) => (
                  <SwiperSlide key={i}>
                    <iframe
                      className="embed-responsive-item"
                      width="250"
                      height="396"
                      src={video.src}
                      title={`Video ${i}`}
                      allowFullScreen
                    ></iframe>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
