import { StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
  },
  section: {
    margin: 20,
    padding: 20,
    border: 1,
    borderRadius: 10,
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  image: {
    width: "100%",
    height: "40%",
  },
  fall: {
    textAlign: "center",
  },
  logo: {
    width: 100,
    height: 25,
    display: "flex",
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  A: {
    margin: 5,
    padding: 5,
    fontSize: 18,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  imagee: {
    width: "30%",
    height: 100,
    resizeMode: "cover",
  },
  day: {
    color: "#DC1B5E",
    textDecoration: "underline",
    fontSize: 16,
  },
  notes: {
    margin: 5,
    padding: 10,
  },
  notesdiv: {
    margin: 5,
    padding: 10,
  },
});

export default styles;
