
import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function ResendOtp() {
  const [mobile, setMobile] = useState('');
  const [timer, setTimer] = useState<number | null>(null);
  const [otpSent, setOtpSent] = useState(false);

  const handleMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const filteredInput = input.replace(/\D/g, ''); 
    setMobile(filteredInput);
  };

  const handleApi = () => {
  
    setTimer(10);
    setOtpSent(true);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === null || prevTimer === 0) {
          clearInterval(interval);
          return null;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (timer === 0 && otpSent) {
      setOtpSent(true);
    }
  }, [timer, otpSent]);

  return (
    <div>
      <Form.Group
        className="mb-3"
        controlId="formGroupPassword"
        style={{ backgroundColor: "black" }}
      >
        <div className="resend">
          <Form.Label className="sendlable2">
            <Button
              onClick={handleApi}
              className="sendotp"
              disabled={timer !== null}
            >
              {timer !== null
                ? `Send OTP in ${timer} seconds`
                : otpSent
                ? "Resend OTP"
                : "Send OTP"}
            </Button>
          </Form.Label>
        </div>

        <Form.Control
          type="text"
          value={mobile}
          placeholder="Mobile Number"
          onChange={handleMobile}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
          }}
          className="your-input-class"
          pattern="[0-9]*"
        />
      </Form.Group>
    </div>
  );
}

export default ResendOtp;

