import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Noimg from "../../assets/images/No-imag.jpg";
import hardimg from "../../assets/images/adventurehard.jpg";
import Label2Image from "../../assets/images/Label2image.png";
import Swal from "sweetalert2";
import { Loader } from "../loader/Loader";

const AdventurePage = () => {
  const location = useLocation();
  const [loading,setLoading]=useState(true)
  const id = location?.state?.adven_id; // Adventure ID (if selected)
  const period = location?.state?.period; // Period ID (the selected month)
const navigate = useNavigate();
  const [adventure, setAdventure] = useState<any[]>([]); // Ensure it's an array

  const searchtour = async () => {
    try {
      // Prepare the payload with both adventure_type and period_id
      const payload: any = {};

      if (id) {
        payload.adventure_type = id; // Send adventure_type if available
      }

      if (period) {
        payload.period = period; // Send period_id if available
      }
setLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/search-tour`,
        payload
      );

      if(response.data.success)
      {
        setLoading(false)
        setAdventure(response.data.getData || []); // Set data as an array
      }
      else{
        navigate("/home");
         Swal.fire({
           icon: "info",
           title: "No Packages Found",
         });
      }
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };

  useEffect(() => {
    searchtour();
  }, []); // Ensure the search runs when the component mounts

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="personalized_img">
            <img src={hardimg} className="img-fluid for_featuredimg" />

            <div className="for_featured_under">
              <p className="for_personalizedtext"> Adventures Tours</p>
            </div>

            <div className="container">
              <div
                style={{ marginTop: "-100px" }}
                className="for-mobile-enquriy-form"
              >
                <div className="diva"></div>
                <div className="container finalWidthfeature">
                  <div className="form-container">
                    <div className="row mt-2 justify-content-center p-3 for_smalldevice">
                      <h3
                        style={{ fontWeight: "700" }}
                        className="text-center perfecttrip"
                      >
                        Not sure what to do? We’ll give you a Call back
                      </h3>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Enter your name"
                          name="name"
                        />
                      </div>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Enter your mail id"
                          name="email"
                        />
                      </div>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Number of travellers"
                          name="numberOfTraveller"
                        />
                      </div>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Enter your phone number"
                          name="mobile"
                        />
                      </div>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <input
                          type="text"
                          className="form-control placeholderclass"
                          placeholder="Enter Your Destination"
                          name="destination"
                          readOnly
                        />
                      </div>
                      <div className="col-12 col-lg-4 col-md-12 p-2">
                        <select
                          className="form-control placeholderclass form-select"
                          name="traveler_month"
                        >
                          <option value="">Select Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 col-md-12 mt-2 featuresubmit">
                        <button className="for_btn">Submit</button>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-container">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Not sure what to do? We’ll give you a Call back
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Enter your name"
                              name="name"
                            />
                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Enter your mail id"
                              name="email"
                            />
                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Number of travellers"
                              name="numberOfTraveller"
                            />
                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Enter your phone number"
                              name="mobile"
                            />
                            <input
                              type="text"
                              className="form-control placeholderclass"
                              placeholder="Enter Your Destination"
                              name="destination"
                              readOnly
                            />
                            <select
                              className="form-control placeholderclass form-select"
                              name="traveler_month"
                            >
                              <option value="">Select Month</option>
                              <option value="January">January</option>
                              <option value="February">February</option>
                              <option value="March">March</option>
                              <option value="April">April</option>
                              <option value="May">May</option>
                              <option value="June">June</option>
                              <option value="July">July</option>
                              <option value="August">August</option>
                              <option value="September">September</option>
                              <option value="October">October</option>
                              <option value="November">November</option>
                              <option value="December">December</option>
                            </select>
                            <button className="for_btn mt-3">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divc2"></div>
              </div>
            </div>
          </div>
          <div className="container my-3">
            <div className="row mt-4">
              <div className="hr_featured mb-2"></div>
              <h1 className="text-center for_backpacking mt-4">
                <span className="pink-text">Featured </span>
                <span className="black-text"> Packages</span>
              </h1>

              {/* Map over the adventure array to display each package */}
              {adventure.map((tour) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2 mt-4"
                  key={tour._id}
                >
                  <div
                    className="card border-0 for_card_inter mb-1"
                    onClick={() => {
                      const id = tour._id;
                      const slugUrl = tour?.title

                        ?.toLowerCase() // Convert to lowercase
                        .replace(/\s+/g, "-");
                      navigate(`/tripdetails/${slugUrl}`, {
                        state: id,
                      });
                    }}
                  >
                    <div className="card border-0 for_card_inter mb-1">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${
                          tour.tour_image?.[0]?.src || ""
                        }`}
                        alt={tour.title || "Featured Package"}
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          if (target) {
                            target.src = Noimg;
                          }
                        }}
                        className="card-img-top-inter2 card-img2"
                      />

                      <div className="for_visitdays mb-4">
                        <h5 className="for_h5">
                          {tour.tourtype === "normal" ? "Group" : tour.tourtype}
                        </h5>
                      </div>
                      <div className="for-sold-out2 mb-4">
                        <img src={Label2Image} className="img-fluiddd" />
                        <div className="LabelName">
                          {tour?.tagLabelNames[0]
                            ? tour?.tagLabelNames[0]
                            : null}
                        </div>
                      </div>
                      <div className="dayandnightw">
                        <p>
                          {tour.nightorday}N/{tour.nightorday + 1}D
                        </p>
                      </div>

                      <div className="card-body">
                        <div className="card-text">
                          <div className="main_para justify-content-between">
                            <h5 className="loremforfeatured">
                              {tour.title?.split(" ").slice(0, 6).join(" ")}
                              {tour.title?.split(" ")?.length > 6 ? "..." : ""}
                            </h5>
                            {tour.location && tour.location !== "undefined" && (
                              <p className="fetured_para">
                                {tour.location
                                  ?.split(" ")
                                  .slice(0, 4)
                                  .join(" ")}
                              </p>
                            )}
                          </div>
                          <div className="d-flex justify-content-between align-items-baseline mt-2">
                            {tour.packageType_price &&
                              tour.packageType_price !== "undefined" && (
                                <p className=" startrate ">
                                  {tour.tourtype === "normal"
                                    ? `Start from ₹${tour?.packageType_price}`
                                    : "Customizable Price"}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdventurePage;
