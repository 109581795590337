import BasicDocument from "./document";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
function PDFViewerPage() {
  const { id }: any = useParams();

  return (
    <div className="App">
      <BasicDocument id={id} />
    </div>
  );
}

export default PDFViewerPage;
