import React from "react";
import "../Cancellation Policy/CancellationPolicy.css"; // Import your CSS file

function CancellationPolicy() {
  return (
    <div className="container">
      <h1>Cancellation Policy</h1>
      <p>
        <strong>Glabol India</strong>
      </p>
      <p>
        Remaining payment should be completed at least 20 days prior to the trip
        or bookings will be cancelled automatically by the system.
      </p>
      <p>
        No Refund shall be made with respect to the initial booking amount for
        any cancellations. However,
      </p>
      <ol>
        <li>
          If cancellations are made 45 - 30 days before the start date of the
          trip, 50% of the trip cost will be charged as cancellation fees.
        </li>
        <li>
          If cancellations are made within 30 days before the start date of the
          trip, 100% of the trip cost will be charged as cancellation fees.
        </li>
        <li>
          In the case of unforeseen weather conditions or government
          restrictions or Local administration/ Union, certain activities may be
          cancelled and in such cases, the operator will try best to provide an
          alternate feasible activity. However, no refund will be provided for
          the same.
        </li>
        <li>
          In case of government orders, harsh weather conditions, protests,
          landslides or any other unforeseen circumstances, we work out the best
          possible alternate plans or trips/treks. At times even certain
          activities are cancelled, the organization will try to provide the
          best alternate but no refunds are provided.
        </li>
        <li>
          Any extra expenses coming due to any natural calamity/unforeseen
          circumstances (like rain, snowfall, earthquake, landslides, strike,
          bandh etc.), Will be borne by customer. Company will not be liable.
        </li>
        <li>
          Bookings / Booking amount is non-transferable, non-adjustable or
          cannot be rescheduled.
        </li>
        <li>
          The Transfer of the bookings is not permitted. Only the names
          mentioned at the time of confirmation shall be allowed to travel.
        </li>
      </ol>
    </div>
  );
}

export default CancellationPolicy;
